import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Img from "gatsby-image"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { EffectFade, Navigation, Keyboard } from "swiper"
import SliderNavigation from "../components/SliderNavigation"
import "swiper/swiper-bundle.css"
import "../components/slider.css"
SwiperCore.use([EffectFade, Navigation, Keyboard])

export default function IndexPage({ data }) {
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const shuffleImages = data.allMarkdownRemark.edges
    .map(({ node }) => node.frontmatter.image.childImageSharp.fluid)
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])
    .splice(0, 5)

  return (
    <Layout>
      <SliderNavigation
        onNext={e => controlledSwiper.navigation.onNextClick(e)}
        onPrev={e => controlledSwiper.navigation.onPrevClick(e)}
      />
      <Swiper
        className={"hidden tablet:block"}
        keyboard
        loop={true}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        preloadImages={false}
        lazy={true}
        speed={600}
        slidesPerView={1}
        onSwiper={setControlledSwiper}
      >
        {shuffleImages.map((image, index) => (
          <SwiperSlide key={index}>
            <Img
              fluid={{
                ...image,
              }}
              imgStyle={{ objectFit: "contain" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={"tablet:hidden"}>
        {shuffleImages.map((image, index) => (
          <Img
            key={index}
            className={"m-5"}
            fluid={{
              ...image,
            }}
            imgStyle={{ objectFit: "contain" }}
          />
        ))}
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const indexPageQuery = graphql`
  query IndexPage {
    allMarkdownRemark(filter: { fields: { contentType: { eq: "media" } } }) {
      edges {
        node {
          frontmatter {
            series
            image {
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  traceSVG: {
                    color: "lightgray"
                    optTolerance: 0.4
                    turdSize: 100
                    alphaMax: 0.5
                    turnPolicy: TURNPOLICY_MINORITY
                  }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
